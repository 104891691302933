/* eslint-disable */
import React from 'react';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import witboxStyles from '../../pages/styles/witbox.module.scss';
import witboxPlus from '../../images/witbox/witbox-plus-thumb-4.svg';
import witboxOne from '../../images/witbox/witbox-one-thumb-4.svg';
import witboxNet from '../../images/witbox/witbox-net-thumb-4.svg';

export default function Whichbox({ id }) {
    const { t } = useTranslation();

    return (
        <>
                <section id={witboxStyles.lineupSection}>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElementWhich}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.cardTitle}>{t('siteMetadata.witbox.whichWitbox')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <LazyLoad offset={100} className={witboxStyles.lineupElementThumb}>
                            <img src={witboxOne} className={witboxStyles.lineupElementIcon} alt='Witbox One' />
                        </LazyLoad>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitle} dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxOne`)}} />
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <LazyLoad offset={100} className={witboxStyles.lineupElementThumb}>
                            <img src={witboxPlus} className={witboxStyles.lineupElementIcon} alt='Witbox Plus' />
                        </LazyLoad>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitle}dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxPlus`)}} />
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <LazyLoad offset={100} className={witboxStyles.lineupElementThumb}>
                            <img src={witboxNet} className={witboxStyles.lineupElementIcon} alt='Witbox Net' />
                        </LazyLoad>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitle} dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxNet`)}} />
                        </div>
                    </li>
                </ul>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitleLeft}>{t('siteMetadata.witbox.supportedDevices')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.stb')}</li>
                                <li>{t('siteMetadata.witbox.smartTVs')}</li>
                                <li>{t('siteMetadata.witbox.streamingMediaBoxes')}</li>
                                <li>{t('siteMetadata.witbox.mediaPlayers')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.stb')}</li>
                                <li>{t('siteMetadata.witbox.smartTVs')}</li>
                                <li>{t('siteMetadata.witbox.streamingMediaBoxes')}</li>
                                <li>{t('siteMetadata.witbox.mediaPlayers')}</li>
                                <li>{t('siteMetadata.witbox.mobileAppDevices')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.webBrowsers')}</li>
                                <li>{t('siteMetadata.witbox.desktopApps')}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitleLeft}>{t('siteMetadata.witbox.videoInterfaces')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.hdmi1')}</li>
                                <li>{t('siteMetadata.witbox.hdmiPassthrough')}</li>
                                <li>{t('siteMetadata.witbox.720p')}</li>
                                <li>{t('siteMetadata.witbox.1080p')}</li>
                                <li>{t('siteMetadata.witbox.4K')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.hdmi4')}</li>
                                <li>{t('siteMetadata.witbox.720p')}</li>
                                <li>{t('siteMetadata.witbox.1080p')}</li>
                                <li>{t('siteMetadata.witbox.4K')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.1080p')}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitleLeft}>{t('siteMetadata.witbox.controllers')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.integratedIR')}</li>
                                <li>{t('siteMetadata.witbox.integratedRF')}</li>
                                <li>{t('siteMetadata.witbox.integratedBT')}</li>
                                <li>{t('siteMetadata.witbox.powerController')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.integratedIR')}</li>
                                <li>{t('siteMetadata.witbox.integratedRF')}</li>
                                <li>{t('siteMetadata.witbox.integratedBT')}</li>
                                <li>{t('siteMetadata.witbox.powerController')}</li>
                                <li>{t('siteMetadata.witbox.usbController')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.hyphen')}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitleLeft}>{t('siteMetadata.witbox.algorithms')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.videoMOS')}</li>
                                <li>{t('siteMetadata.witbox.audioMOS')}</li>
                                <li>{t('siteMetadata.witbox.vqid')}</li>
                                <li>{t('siteMetadata.witbox.smartping')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.videoMOS')}</li>
                                <li>{t('siteMetadata.witbox.audioMOS')}</li>
                                <li>{t('siteMetadata.witbox.vqid')}</li>
                                <li>{t('siteMetadata.witbox.smartping')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.videoMOS')}</li>
                                <li>{t('siteMetadata.witbox.audioMOS')}</li>
                                <li>{t('siteMetadata.witbox.vqid')}</li>
                                <li>{t('siteMetadata.witbox.smartping')}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitleLeft}>{t('siteMetadata.witbox.networkOptions')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.hyphen')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.bridgeForEthernetSTB')}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li>{t('siteMetadata.witbox.wifi6E')}</li>
                                <li>{t('siteMetadata.witbox.ethernetBase')}</li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul id={witboxStyles.lineupList}>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <h3 className={witboxStyles.lineupElementTitleLeft}>{t('siteMetadata.witbox.summary')}</h3>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxOneSummary`)}} />
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxPlusSummary`)}} />
                            </ul>
                        </div>
                    </li>
                    <li className={witboxStyles.lineupElement}>
                        <div className={witboxStyles.lineupElementText}>
                            <ul className={witboxStyles.lineupSpecs}>
                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxNetSummary`)}} />
                            </ul>
                        </div>
                    </li>
                </ul>
            </section>
        </>
    );
}

/* eslint-disable */
import React from 'react';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
// import { faChevronRight } from '@witbe/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import Button from '@witbe/ui-react-components/dist/Button';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useOpenContactModal, useViewport } from '../../hooks';
import styles from '../styles/products.module.scss';
import witboxStyles from '../styles/witbox.module.scss';
import productStyles from '../styles/newProducts.module.scss';
import techStyles from '../styles/newTechnology.module.scss';
import technologyStyles from '../styles/technology.module.scss';
import homeStyles from '../styles/newHome.module.scss';
import ogimage from '../../images/witbox/witbox-hero.jpg';
import ogimageMobile from '../../images/witbox/witbox-mobile-hero.jpg';
import WorkFromAnywhere from '../../images/index/work_from_anywhere.jpg';
import witboxPlus from '../../images/witbox/witboxplus-thumb.svg';
import witboxOne from '../../images/witbox/witboxone-thumb.svg';
import witboxNet from '../../images/witbox/witboxnet-thumb.svg';
import WitboxThumb from '../../images/index/thumbs/witbox_thumb.jpg';
import witFam from '../../images/witbox/the-witbox-family.png';
import meetWitbox from '../../images/witbox/meet-the-witbox.jpg';
import remotely from '../../images/witbox/remotely-take-control-of-all-your-real-devices-in-the-field.jpg';
import stormtest from '../../images/witbox/the-best-alternative-to-stormtest.jpg';
import slingbox from '../../images/witbox/the-best-slingbox-alternative.jpg';
import Customers from '../../components/common/Customers';
import Whichbox from '../../components/common/Whichbox';
import WhichboxMobile from '../../components/common/WhichboxMobile';
import WhichboxTablet from '../../components/common/WhichboxTablet';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

const { WITBOX } = MODAL_SOURCE_BUTTONS;

const Witbox = () => {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;
    const breakpointTablets = 821;

    const openContactModal = useOpenContactModal(WITBOX);

    const data = useStaticQuery(graphql`
        query WitboxImages {
            witboxHero: file(relativePath : { eq: "witbox/witbox-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            witboxHeroMobile: file(relativePath : { eq: "witbox/witbox-mobile-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            meetWitbox: file(relativePath : { eq: "witbox/meet-the-witbox.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            meetWitboxMobile: file(relativePath : { eq: "witbox/meet-the-witbox.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            rec: file(relativePath : { eq: "witbox/remotely-take-control-of-all-your-real-devices-in-the-field.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            recMobile: file(relativePath : { eq: "witbox/remotely-take-control-of-all-your-real-devices-in-the-field.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            witboxHand: file(relativePath : { eq: "witbox/the-best-slingbox-alternative.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            witboxHandMobile: file(relativePath : { eq: "witbox/the-best-slingbox-alternative.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nfl: file(relativePath : { eq: "witbox/the-best-alternative-to-stormtest.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nflMobile: file(relativePath : { eq: "witbox/the-best-alternative-to-stormtest.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: NONE
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            }
        }
    `);

    const imageWitboxHero = getImage(data.witboxHero);
    const imageWitboxHeroMobile = getImage(data.imageWitboxHeroMobile);
    const imageMeetWitbox = getImage(data.meetWitbox);
    const imageMeetWitboxMobile = getImage(data.imageMeetWitboxMobile);
    const imageREC = getImage(data.rec);
    const imageRECMobile = getImage(data.recMobile);
    const imageWitbox = getImage(data.witboxHand);
    const imageWitboxMobile = getImage(data.witboxHandMobile);
    const imageNFL = getImage(data.nfl);
    const imageNFLMobile = getImage(data.nflMobile);

    return (
        <Layout title='Witbe - Witbox'>
            <SEO
                title='Witbe - Witbox'
                ogDescription='For more than 20 years, Witbe provides technologies and services to remotely access devices as well as automatically test and monitor any services on any devices.'
                description='For more than 20 years, Witbe provides technologies and services to remotely access devices as well as automatically test and monitor any services on any devices.'
                image={ogimage}
            />
            <div id={techStyles.mainImageWrapper}>
                {width < breakpointTablets ?
                <div className={techStyles.mainImageTextWrapper}>
                    <div className={techStyles.mainImageLeft}>
                        <div className={techStyles.mainImageContentMobile}>
                            <h1 className={techStyles.mainImageTitle}>
                                {t('siteMetadata.witbox.meet')}
                            </h1>
                            <p className={techStyles.mainImageSub}>{t('siteMetadata.witbox.meetSub')}</p>
                            <div className={techStyles.learnMoreBuyButtons}>
                                <Link
                                    to='/products/witbox/'
                                    className={techStyles.blueButtonMobile + ' ' + styles.buyButton}
                                    onClick={openContactModal}
                                >
                                    {t('siteMetadata.home.contactUs')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={techStyles.mainImageRight}>
                        <div className={techStyles.rightImageWrapper}>
                            <img src={ogimageMobile} alt='Banner' />
                        </div>
                    </div>
                </div>
                :
                <div className={techStyles.mainImageTextWrapper}>
                    <GatsbyImage image={imageWitboxHero} alt='Witbox' className={homeStyles.gatsbyImage} />
                    {/* <img src={ogimage} id={techStyles.mainImg} alt='Banner' /> */}
                    <div className={witboxStyles.mainImageContent}>
                        <h1 className={techStyles.mainImageTitle}>
                            {t('siteMetadata.witbox.meet')}
                        </h1>
                        <p className={techStyles.mainImageSub}>{t('siteMetadata.witbox.meetSub')}</p>
                        <div className={techStyles.learnMoreBuyButtons}>
                            <Link
                                to='/products/witbox/'
                                className={homeStyles.transparentButton + ' ' + homeStyles.buyButton}
                                onClick={openContactModal}
                            >
                                {t('siteMetadata.home.contactUs')}
                            </Link>
                        </div>
                    </div>
                </div>
                }
            </div>
            {width < breakpoint ? <WhichboxMobile /> : null}
            {width > breakpoint && width < breakpointTablets ? <WhichboxTablet /> : null}
            {width > breakpointTablets ? <Whichbox /> : null}
            <section id={productStyles.blueSection}>
                <h2 id={productStyles.blueSectionTitle}>
                    {t('siteMetadata.products.ready')}
                </h2>
                <div className={productStyles.blueSectionText}>
                    <p>{t('siteMetadata.products.readySub')}</p>
                </div>
                {width < breakpoint ?
                    (
                        <div className={homeStyles.witboxButtons}>
                            <Link
                                to='/products/witbox/'
                                className={homeStyles.whiteButtonMobile + ' ' + homeStyles.buyButton}
                                onClick={openContactModal}
                            >
                                {t('siteMetadata.home.contactUs')}
                            </Link>
                        </div>
                    )
                    :
                    (
                        <div className={homeStyles.witboxButtons}>
                            <Link
                                to='/products/witbox/'
                                className={homeStyles.whiteButton + ' ' + homeStyles.buyButton}
                                onClick={openContactModal}
                            >
                                {t('siteMetadata.home.contactUs')}
                            </Link>
                        </div>
                    )
                }
            </section>
            {width < breakpoint ?
                (
                    <section id={homeStyles.quadColSectionMobile}>
                        <h2 id={homeStyles.quadColTitleMobile}>
                            {t('siteMetadata.witbox.witboxAction')}
                        </h2>
                        <ul id={homeStyles.quadColListMobile}>
                            <Link to='/articles/meet-the-witbox'>
                                <li
                                    className={homeStyles.itemMobile}
                                >
                                    <div className={homeStyles.mobileCardImageContainer}>
                                        <LazyLoad offset={100} className={homeStyles.cardImageWrapperMobile}>
                                            <img src={meetWitbox} className={homeStyles.cover} alt='Witbox' />
                                        </LazyLoad>
                                    </div>
                                    <div className={homeStyles.itemContentMobile}>
                                        <h3 className={homeStyles.itemTitleMobile}>{t('siteMetadata.witbox.meetWitbox')}</h3>
                                        <p className={homeStyles.itemParagraphMobile}>{t('siteMetadata.witbox.meetWitboxSub')}</p>
                                        <p className={homeStyles.itemLearnMobile}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link to='/articles/rec-use-cases/'>
                                <li
                                    className={homeStyles.itemMobile}
                                >
                                    <div className={homeStyles.mobileCardImageContainer}>
                                        <LazyLoad offset={100} className={homeStyles.cardImageWrapperMobile}>
                                            <img src={remotely} className={homeStyles.cover} alt='Witbe Cloud' />
                                        </LazyLoad>
                                    </div>
                                    <div className={homeStyles.itemContentMobile}>
                                        <h3 className={homeStyles.itemTitleMobile}>{t('siteMetadata.witbox.remotely')}</h3>
                                        <p className={homeStyles.itemParagraphMobile}>{t('siteMetadata.witbox.remotelySub')}</p>
                                        <p className={homeStyles.itemLearnMobile}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link to='/articles/witbox-the-best-slingbox-alternative/'>
                                <li
                                    className={homeStyles.itemMobile}
                                >
                                    <div className={homeStyles.mobileCardImageContainer}>
                                        <LazyLoad offset={100} className={homeStyles.cardImageWrapperMobile}>
                                            <img src={slingbox} className={homeStyles.cover} alt='Updates' />
                                        </LazyLoad>
                                    </div>
                                    <div className={homeStyles.itemContentMobile}>
                                        <h3 className={homeStyles.itemTitleMobile}>{t('siteMetadata.witbox.slingboxAlt')}</h3>
                                        <p className={homeStyles.itemParagraphMobile}>{t('siteMetadata.witbox.slingboxAltSub')}</p>
                                        <p className={homeStyles.itemLearnMobile}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/articles/the-perfect-replacement-to-stormtest'
                                className={homeStyles.blueLink}
                            >
                                <li
                                    className={homeStyles.itemMobile}
                                >
                                    <div className={homeStyles.mobileCardImageContainer}>
                                        <LazyLoad offset={100} className={homeStyles.cardImageWrapperMobile}>
                                            <img src={stormtest} className={homeStyles.cover} alt='Contact' />
                                        </LazyLoad>
                                    </div>
                                    <div className={homeStyles.itemContentMobile}>
                                        <h3 className={homeStyles.itemTitleMobile}>{t('siteMetadata.witbox.stormtestAlt')}</h3>
                                        <p className={homeStyles.itemParagraphMobile}>{t('siteMetadata.witbox.stormtestAltSub')}</p>
                                        <p className={homeStyles.itemLearnMobile}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </section>
                )
                :
                (
                    <section id={homeStyles.quadColSection}>
                        <h2 id={homeStyles.quadColTitle}>
                            {t('siteMetadata.witbox.witboxAction')}
                        </h2>
                        <ul id={homeStyles.quadColList}>
                            <Link to='/articles/meet-the-witbox'>
                                <li className={homeStyles.item}>
                                    <LazyLoad offset={400} className={homeStyles.itemThumb}>
                                        <GatsbyImage image={imageMeetWitbox} alt='Meet the Witbox' className={homeStyles.itemIcon} />
                                        {/* <img src={meetWitbox} className={homeStyles.itemIcon} alt='Test In Labs' /> */}
                                    </LazyLoad>
                                    <div className={homeStyles.itemText}>
                                        <h3 className={homeStyles.itemTitle}>{t('siteMetadata.witbox.meetWitbox')}</h3>
                                        <p className={homeStyles.itemParagraph}>{t('siteMetadata.witbox.meetWitboxSub')}</p>
                                        <p className={homeStyles.itemLearnMore}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link to='/articles/rec-use-cases/'>
                                <li className={homeStyles.item}>
                                    <LazyLoad offset={400} className={homeStyles.itemThumb}>
                                        <GatsbyImage image={imageREC} alt='REC' className={homeStyles.itemIcon} />
                                        {/* <img src={remotely} className={homeStyles.itemIcon} alt='Test In Fields' /> */}
                                    </LazyLoad>
                                    <div className={homeStyles.itemText}>
                                        <h3 className={homeStyles.itemTitle}>{t('siteMetadata.witbox.remotely')}</h3>
                                        <p className={homeStyles.itemParagraph}>{t('siteMetadata.witbox.remotelySub')}</p>
                                        <p className={homeStyles.itemLearnMore}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link to='/articles/witbox-the-best-slingbox-alternative/'>
                                <li className={homeStyles.item}>
                                    <LazyLoad offset={400} className={homeStyles.itemThumb}>
                                        <GatsbyImage image={imageWitbox} alt='Witbox' className={homeStyles.itemIcon} />
                                        {/* <img src={slingbox} className={homeStyles.itemIcon} alt='Proactive Monitoring' /> */}
                                    </LazyLoad>
                                    <div className={homeStyles.itemText}>
                                        <h3 className={homeStyles.itemTitle}>{t('siteMetadata.witbox.slingboxAlt')}</h3>
                                        <p className={homeStyles.itemParagraph}>{t('siteMetadata.witbox.slingboxAltSub')}</p>
                                        <p className={homeStyles.itemLearnMore}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/articles/the-perfect-replacement-to-stormtest'
                            >
                                <li className={homeStyles.item}>
                                    <LazyLoad offset={400} className={homeStyles.itemThumb}>
                                        <GatsbyImage image={imageNFL} alt='Stormtest' className={homeStyles.itemIcon} />
                                        {/* <img src={stormtest} className={homeStyles.itemIcon} alt='Proactive Monitoring' /> */}
                                    </LazyLoad>
                                    <div className={homeStyles.itemText}>
                                        <h3 className={homeStyles.itemTitle}>{t('siteMetadata.witbox.stormtestAlt')}</h3>
                                        <p className={homeStyles.itemParagraph}>{t('siteMetadata.witbox.stormtestAltSub')}</p>
                                        <p className={homeStyles.itemLearnMore}>
                                            {t('siteMetadata.index.learnMoreButton')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={homeStyles.chevronRight}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </section>
                )
            }
            <Customers />
        </Layout>
    );
};

export default Witbox;

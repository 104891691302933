/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import newStyles from '../../pages/styles/newProducts.module.scss';
import witboxStyles from '../../pages/styles/witbox.module.scss';
import witboxPlus from '../../images/witbox/witbox-plus-thumb-4.svg';
import witboxOne from '../../images/witbox/witbox-one-thumb-4.svg';
import witboxNet from '../../images/witbox/witbox-net-thumb-4.svg';

export default function WhichboxMobile({ id }) {
    const { t } = useTranslation();

    const options = [
        { label: 'WitboxOne', value: 'WitboxOne' },
        { label: 'Witbox+', value: 'Witbox+' },
        { label: 'WitboxNet', value: 'WitboxNet' },
      ];

    const [value, setValue] = useState('WitboxOne');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const textStyle1 = value === 'WitboxOne' ? witboxStyles.cardVisibleWitboxOne : witboxStyles.cardInvisibleWitboxOne;
    const textStyle2 = value === 'Witbox+' ? witboxStyles.cardVisibleWitboxPlus : witboxStyles.cardVisibleWitboxPlus;
    const textStyle3 = value === 'WitboxNet' ? witboxStyles.cardVisibleWitboxNet : witboxStyles.cardVisibleWitboxNet;

    return (
        <>
                <section id={newStyles.interactiveSection}>
                    <div className={witboxStyles.lineupElementText}>
                        <h3 className={witboxStyles.cardTitle}>{t('siteMetadata.witbox.whichWitbox')}</h3>
                    </div>
                    <div className={witboxStyles.dropdown}>
                        <label>
                            <select value={value} onChange={handleChange} className={witboxStyles.select}>
                                {options.map((option) => (
                                    <option value={option.value} className={witboxStyles.whichWitboxOption}>{option.label}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className={newStyles.interactiveSectionWrapper}>
                        <ul id={newStyles.interactiveList}>
                            {value === 'WitboxOne' ?
                                <li className={textStyle1}>
                                {/* <div className={newStyles.cardText}>
                                    <h3 className={witboxStyles.lineupElementTitle}>{value}</h3>
                                </div> */}
                                <div>
                                    <div className={newStyles.interactiveImage}>
                                        <img src={witboxOne} className={witboxStyles.imageVisibleWitboxOne} alt='WitboxOne' />
                                    </div>
                                    <div className={newStyles.cardText}>
                                        <h3 className={witboxStyles.cardTitleSpecs}>
                                            {t('siteMetadata.witbox.supportedDevices')}
                                        </h3>
                                        <ul className={witboxStyles.lineupSpecs}>
                                            <li>{t('siteMetadata.witbox.stb')}</li>
                                            <li>{t('siteMetadata.witbox.smartTVs')}</li>
                                            <li>{t('siteMetadata.witbox.streamingMediaBoxes')}</li>
                                            <li>{t('siteMetadata.witbox.mediaPlayers')}</li>
                                        </ul>
                                        <h3 className={witboxStyles.cardTitleSpecs}>
                                            {t('siteMetadata.witbox.videoInterfaces')}
                                        </h3>
                                        <ul className={witboxStyles.lineupSpecs}>
                                            <li>{t('siteMetadata.witbox.hdmi1')}</li>
                                            <li>{t('siteMetadata.witbox.hdmiPassthrough')}</li>
                                            <li>{t('siteMetadata.witbox.720p')}</li>
                                            <li>{t('siteMetadata.witbox.1080p')}</li>
                                            <li>{t('siteMetadata.witbox.4K')}</li>
                                        </ul>
                                        <h3 className={witboxStyles.cardTitleSpecs}>
                                            {t('siteMetadata.witbox.controllers')}
                                        </h3>
                                        <ul className={witboxStyles.lineupSpecs}>
                                            <li>{t('siteMetadata.witbox.integratedIR')}</li>
                                            <li>{t('siteMetadata.witbox.integratedRF')}</li>
                                            <li>{t('siteMetadata.witbox.integratedBT')}</li>
                                            <li>{t('siteMetadata.witbox.powerController')}</li>
                                        </ul>
                                        <h3 className={witboxStyles.cardTitleSpecs}>
                                            {t('siteMetadata.witbox.algorithms')}
                                        </h3>
                                        <ul className={witboxStyles.lineupSpecs}>
                                            <li>{t('siteMetadata.witbox.videoMOS')}</li>
                                            <li>{t('siteMetadata.witbox.audioMOS')}</li>
                                            <li>{t('siteMetadata.witbox.vqid')}</li>
                                            <li>{t('siteMetadata.witbox.smartping')}</li>
                                        </ul>
                                        <h3 className={witboxStyles.cardTitleSpecs}>
                                            {t('siteMetadata.witbox.networkOptions')}
                                        </h3>
                                        <ul className={witboxStyles.lineupSpecs}>
                                            <li>{t('siteMetadata.witbox.hyphen')}</li>
                                        </ul>
                                        <h3 className={witboxStyles.cardTitleSpecs}>
                                            {t('siteMetadata.witbox.summary')}
                                        </h3>
                                        <ul className={witboxStyles.lineupSpecs}>
                                            <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxOneSummary`)}} />
                                        </ul>
                                    </div>
                                </div>
                                </li>
                            :null}
                            {value === 'Witbox+' ?
                                <li className={textStyle2}>
                                    {/* <div className={newStyles.cardText}>
                                        <h3 className={witboxStyles.lineupElementTitle} dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxPlus`)}} />
                                    </div> */}
                                    <div>
                                        <div className={newStyles.interactiveImage}>
                                            <img src={witboxPlus} className={witboxStyles.imageVisibleWitboxPlus} alt='Witbox+' />
                                        </div>
                                        <div className={newStyles.cardText}>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.supportedDevices')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.stb')}</li>
                                                <li>{t('siteMetadata.witbox.smartTVs')}</li>
                                                <li>{t('siteMetadata.witbox.streamingMediaBoxes')}</li>
                                                <li>{t('siteMetadata.witbox.mediaPlayers')}</li>
                                                <li>{t('siteMetadata.witbox.mobileAppDevices')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.videoInterfaces')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.hdmi4')}</li>
                                                <li>{t('siteMetadata.witbox.720p')}</li>
                                                <li>{t('siteMetadata.witbox.1080p')}</li>
                                                <li>{t('siteMetadata.witbox.4K')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.controllers')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.integratedIR')}</li>
                                                <li>{t('siteMetadata.witbox.integratedRF')}</li>
                                                <li>{t('siteMetadata.witbox.integratedBT')}</li>
                                                <li>{t('siteMetadata.witbox.powerController')}</li>
                                                <li>{t('siteMetadata.witbox.usbController')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.algorithms')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.videoMOS')}</li>
                                                <li>{t('siteMetadata.witbox.audioMOS')}</li>
                                                <li>{t('siteMetadata.witbox.vqid')}</li>
                                                <li>{t('siteMetadata.witbox.smartping')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.networkOptions')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.bridgeForEthernetSTB')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.summary')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxPlusSummary`)}} />
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            :null}
                            {value === 'WitboxNet' ?
                                <li className={textStyle3}>
                                    {/* <div className={newStyles.cardText}>
                                        <h3 className={witboxStyles.lineupElementTitle} dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxNet`)}} />
                                    </div> */}
                                    <div>
                                        <div className={newStyles.interactiveImage}>
                                            <img src={witboxNet} className={witboxStyles.imageVisibleWitboxNet} alt='WitboxNet' />
                                        </div>
                                        <div className={newStyles.cardText}>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.supportedDevices')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.webBrowsers')}</li>
                                                <li>{t('siteMetadata.witbox.desktopApps')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.videoInterfaces')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.1080p')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.controllers')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.hyphen')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.algorithms')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.videoMOS')}</li>
                                                <li>{t('siteMetadata.witbox.audioMOS')}</li>
                                                <li>{t('siteMetadata.witbox.vqid')}</li>
                                                <li>{t('siteMetadata.witbox.smartping')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.networkOptions')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li>{t('siteMetadata.witbox.wifi6E')}</li>
                                                <li>{t('siteMetadata.witbox.ethernetBase')}</li>
                                            </ul>
                                            <h3 className={witboxStyles.cardTitleSpecs}>
                                                {t('siteMetadata.witbox.summary')}
                                            </h3>
                                            <ul className={witboxStyles.lineupSpecs}>
                                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.witbox.witboxNetSummary`)}} />
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            :null}
                        </ul>
                    </div>
                </section>
        </>
    );
}
